import { enableES5, produce } from "immer";
import _ from "lodash";
import { GET_STUDY_DATA } from "../actions/study/getStudyData";
import { CHANGE_WEBSITE_CONTENT } from "../actions/study/changeWebSiteContent";
import { CHANGE_WEBSITE_CONTENT_UPLOADS } from "../actions/study/changeWebSiteContentUploads";
import { DELETE_WEBSITE_CONTENT_UPLOADS } from "../actions/study/deleteWebSiteContentUploads";

import { default_culture } from "../assets/localization/resources";

const initState = {
  Culture: default_culture,
};
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case GET_STUDY_DATA: {
      return action.payload;
    }
    case CHANGE_WEBSITE_CONTENT: {
      let websiteContentItem = draft.WebSiteContent.find((x) => x.ID === action.payload.ID);

      if (!_.isEmpty(websiteContentItem)) {
        websiteContentItem.Value = action.payload.Value;
      }
      return;
    }
    case CHANGE_WEBSITE_CONTENT_UPLOADS: {
      let { id, name, value, order, file, fileName, contentType, webContentType, culture, url } = action.payload;
      let websiteContentItem = null;
      if (webContentType == 1) {
        websiteContentItem = draft.TeamContent.find((x) => x.ID === id);
        if (websiteContentItem == null) {
          websiteContentItem = { ID: id, WebContentType: webContentType, CultureID: culture };
          draft.TeamContent.push(websiteContentItem);
        }
      }
      if (webContentType == 2) {
        websiteContentItem = draft.TestimonialSliderContent.find((x) => x.ID === id);
        if (websiteContentItem == null) {
          websiteContentItem = { ID: id, WebContentType: webContentType, CultureID: culture };
          draft.TestimonialSliderContent.push(websiteContentItem);
        }
      }

      if (webContentType == 5) {
        websiteContentItem = draft.ToolBoxData.find((x) => x.ID === id);
        if (websiteContentItem == null) {
          websiteContentItem = { ID: id, WebContentType: webContentType, CultureID: culture };
          draft.ToolBoxData.push(websiteContentItem);
        }
      }

      if (webContentType == 6) {
        websiteContentItem = draft.StoryData.find((x) => x.ID === id);
        if (websiteContentItem == null) {
          websiteContentItem = { ID: id, WebContentType: webContentType, CultureID: culture };
          draft.StoryData.push(websiteContentItem);
        }
      }

      if (!_.isEmpty(websiteContentItem)) {
        websiteContentItem.Name = name;
        websiteContentItem.Value = value;
        websiteContentItem.File = file;
        websiteContentItem.Order = order;
        websiteContentItem.FileName = fileName;
        websiteContentItem.ContentType = contentType;
        websiteContentItem.URL = url;
      }
      return;
    }
    case DELETE_WEBSITE_CONTENT_UPLOADS: {
      let { id, contentType } = action.payload;
      if (contentType == 2) {
        const index = draft.TestimonialSliderContent.findIndex((x) => x.ID === id);
        if (index !== -1) draft.TestimonialSliderContent.splice(index, 1);
      }
      if (contentType == 5) {
        const index = draft.ToolBoxData.findIndex((x) => x.ID === id);
        if (index !== -1) draft.ToolBoxData.splice(index, 1);
      }
      if (contentType == 6) {
        const index = draft.StoryData.findIndex((x) => x.ID === id);
        if (index !== -1) draft.StoryData.splice(index, 1);
      }

      return;
    }

    default:
      return;
  }
}, initState);
