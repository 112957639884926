import { combineReducers } from "redux";
import studyReducer from "./studyReducer";
import { questionnaires } from "./questionnairesReducer";
import { authentication } from "./authenticationReducer";
import userQuestionnaireReducer from "./userQuestionnaire";
import handleSectionDataReducer from "./Section/handleSectionDataReducer";
import tempStorageReducer from "./tempStorage";
import loadingReducer from "./loadingReducer";
import { form } from "./formReducer";
import { pages } from "./pagesReducer";
import faqReducer from "./faqReducer";
import { followup } from "./followupReducer";

const rootReducer = combineReducers({
  study: studyReducer,
  faq: faqReducer,
  authentication,
  questionnaires,
  userQuestionnaire: userQuestionnaireReducer,
  sections: handleSectionDataReducer,
  tempStorage: tempStorageReducer,
  loading: loadingReducer,
  form,
  pages,
  followup,
});

export default rootReducer;
